<template>
  <div>
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="modal.create" size="lg">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/city-hall_white.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ modal.title }}</h5>
        </div>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <multi-filter
            ref="multi-filter"
            @fetch="init"
            :filter="filter"
            :type="4"
            :showSlotOrderBy="false"
            :status="null"
          />
          <div class="container-fluid mb-2 mt-n2"
            v-if="!needToSelectEntity">
            <div class="row">
              <div class="col-12 text-right">
                <base-button
                  style="margin: 0;width: 195px; justify-content: center; min-height: 28px; padding: 0; font-size: 11px; display: inline-flex; align-items: center;"
                  @click.prevent="handleSelect(null)"
                  class="ml-2 btn text-uppercase colorize-btn-img btn-success"
                  type="button">
                  Continuar sem Incluir
                </base-button>
              </div>
            </div>
          </div>
          <div class="container-fluid">
            <div class="row card-wrapper" v-show="loadingSkeleton">
              <SkeletonPuzlGrid v-for="index in 3" :key="index" />
            </div>
            <PuzlEmptyData v-if="!items.length && !loadingSkeleton" />
            <form
              class="needs-validation"
              @submit.prevent="handleSubmit(save)"
              autocomplete="off"
            >
              <div class="card-with-box-shadow mb-3"
                v-if="items.length && !loadingSkeleton"
                v-for="(item, index) in items"
                :key="index">
                <div class="px-4 py-1 mb-1 mt-3">
                  <div class="row">
                    <div class="col-12">
                      <h4 class="m-0 p-0 mr-3 new-default-black-font" style="font-size: 1rem;">
                        {{ item.entity_name }}
                      </h4>
                    </div>
                    <div class="col-12 mt-1 new-default-gray-font font-weight-400" style="font-size: 12px">
                      {{ item.document }}
                    </div>
                  </div>
                </div>
                <div class="row mt-n4">
                  <div class="offset-6 col-6">
                    <div class="card-blue-bottom text-center pointer" @click="handleSelect(item)">
                      <a style="height: 35px; display: inline-block;"
                        href="#">
                        <h3 style="text-transform: none;padding: 5px 3px 5px 5px;">
                          <span class="mr-1">
                            <img src="/img/icons/icons8/ios/right-squared.png" width="20px">
                          </span>
                          <small class="mr-1 text-white" style="font-size: 12px">
                            Selecionar
                          </small>
                        </h3>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <pagination @navegate="navegate" :source="source"/>
          </div>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MultiFilter from "@/components/Utils/MultiFilter.vue";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import Pagination from "@/components/Utils/Pagination.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";

export default {
  name: "ModalSearchEntity",
  data() {
    return {
      modal: {
        title: "CNPJ",
        create: false,
      },
      filter: {
        status: null,
      },
      paginate: {
        page: null,
      },
      loadingSkeleton: true,
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
      },
      needToSelectEntity: true,
      source: null,
      parent_payload : null,
      currentType: null, // Pode ser "guarantor_entity" ou "business_partner"
    };
  },
  components: { MultiFilter, SkeletonPuzlGrid, Pagination, PuzlEmptyData },
  computed: {
    ...mapGetters({
      items: "entity/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    async openModal(parent_payload = null, needToSelectEntity = true, currentType = null) {
      this.currentType = currentType;
      this.needToSelectEntity = needToSelectEntity;
      // this.$refs['multi-filter'].clearFilters(false);
      this.$Progress.start();
      this.filter = {
        status: null,
        page: 1,
      };
      this.parent_payload = parent_payload;
      this.paginate.page = 1;
      await this.init();
      this.$Progress.finish();
      this.modal.create = true;
    },
    init(filter = null) {
      this.filter = filter ?? this.filter;
      if(this.parent_payload && this.parent_payload.is_fuel) {
        this.filter.is_fuel = true;
      }
      this.loadingSkeleton = true;
      return this.$store
        .dispatch("entity/fetchItems", {
          filter: this.filter,
          page: this.paginate.page,
        })
        .then((response) => {
          this.source = response;
          this.loadingSkeleton = false;
        });
    },
    clearFilters() {
      this.filter =  {
        status: null,
      };
      this.paginate.page = 1;
      this.init();
    },
    navegate(page) {
      this.paginate.page = page;
      this.init();
    },
    handleSelect(entity) {
      this.$emit("selected", entity, this.parent_payload, this.currentType);
      this.closeModal();
    },
  },
};
</script>

<style scoped></style>
