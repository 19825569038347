<template>
  <div>
    <base-header class="bg-gray-content mb-n4">
      <div class="row align-items-center py-4 mb-n2">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important;" class="text-success">
            <img
              height="30px"
              :src="type === 'contract' ? '/img/icons/client-company.png' : '/img/icons/client-company-gray.png'"
              class="mr-2"
            />
            <span :style="type === 'contract' ? '' : 'color: #606062'">
              {{ type === 'contract' ? 'CONTRATOS' : 'PROPOSTAS' }}
            </span>
          </h2>
        </div>
        <div class="col-md-8">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md-3 px-1">

              <base-button
                v-if="type === 'contract'"
                block
                style="font-size: 0.8rem;"
                @click.prevent="openModalSearchEntityAndConstruction()"
                slot="title-container" class=" text-uppercase" type="success">
                <img src="/img/icons/plus-math--v1-white.png" width="22"> NOVO
              </base-button>
              <base-button
                v-else
                block
                style="font-size: 0.8rem;"
                @click.prevent="handleShowModalCreateTemporaryCustomerConstruction()"
                slot="title-container" class=" text-uppercase" type="success">
                <img src="/img/icons/plus-math--v1-white.png" width="22"> NOVO
              </base-button>
            </div>
            <base-dropdown
              v-if="$hasPermission('commercial.contract-proposal.details.view')"
              class="mb-md-0 mb-2 col-md-3 px-1"
            >
              <base-button
                block
                style="font-size: 0.8rem;"
                slot="title-container" class="dropdown-toggle text-uppercase" type="warning">
                <img src="/img/icons/product-documents.png" width="22px" height="22px">
                Cadastros
              </base-button>
              <div>
                <router-link :to="{
                  path: '/commercial/contract-proposal/situations',
                  name: 'commercial.contract-proposal.situations.index'
                }">
                  <span class="new-default-black-font dropdown-item">
                    <img height="19" src="/img/icons/icons8/ios/traffic-light_success.png" class="mr-1 mt-n1"/>
                    Situação de Contrato
                  </span>
                </router-link>
                <div class="dropdown-divider p-0 m-0"></div>
                <router-link :to="{
                  name: 'Motivo de situação do contrato',
                  path: '/commercial/contract-proposal/reason-cancellation'
                }">
                  <span class="new-default-black-font dropdown-item">
                    <img height="19" src="/img/icons/icons8/ios/questions_danger.png" class="mr-1 mt-n1"/>
                    Motivo de situação do contrato
                  </span>
                </router-link>
                <div class="dropdown-divider p-0 m-0"/>
                <a href="#" class="new-default-black-font dropdown-item"
                   @click.prevent="handleShowModalDigitalSignature()">
                  <img height="19" src="/img/icons/autograph-black.png" alt="autograph" class="mr-1 mt-n1"/>
                  Assinatura digital
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <router-link :to="{
                  path: '/commercial/contract-proposal/competitors',
                  name: 'commercial.contract-proposal.competitors.index'
                }">
                  <span class="new-default-black-font dropdown-item">
                    <img height="19" src="/img/icons/icons8/ios/leaderboard.png" class="mr-1 mt-n1"/>
                    Concorrentes
                  </span>
                </router-link>
                <div class="dropdown-divider p-0 m-0"></div>
                <router-link :to="{
                  path: '/commercial/contract-proposal/contracts',
                  name: 'commercial.contract-proposal.contracts.index'
                }">
                  <span class="new-default-black-font dropdown-item">
                    <img height="19" src="/img/icons/icons8/ios/fine-print--v1_primary.png" class="mr-1 mt-n1"/>
                    Termos de Contrato
                  </span>
                </router-link>
                <div class="dropdown-divider p-0 m-0"/>
                <a href="#" class="new-default-black-font dropdown-item"
                   @click.prevent="handleShowModalProposalTerms()">
                  <img height="19" src="/img/icons/icons8/ios/term_warning.png" class="mr-1 mt-n1"/>
                  Termos de proposta
                </a>
              </div>
            </base-dropdown>
            <base-dropdown
              v-show="has_permission('commercial.report-contract-proposal.index.view')"
              class="mb-md-0 mb-2 col-md-3 px-1"
            >
              <base-button
                block
                style="font-size: 0.8rem;"
                slot="title-container" class="text-uppercase dropdown-toggle" type="dark">
                <img src="/img/icons/graph-report.png" width="22px" height="22px">
                RELATÓRIOS
              </base-button>
              <div>
                <span @click.prevent="handleShowModalReport()" class="new-default-black-font dropdown-item">
                  <img height="19" src="/img/icons/check.png" class="mr-1 mt-n1"/>
                  Faturamento
                </span>
                <div class="dropdown-divider p-0 m-0"></div>
                <span @click.prevent="handleShowModalScheduleReport()" class="new-default-black-font dropdown-item">
                  <img height="19" src="/img/icons/icons8/ios/successful-delivery_success.png" class="mr-1 mt-n1"/>
                  Entregas
                </span>
                <span @click.prevent="handleShowModalScheduleReport(is_new_layout)" class="new-default-black-font dropdown-item">
                  <img height="19" src="/img/icons/icons8/ios/successful-delivery_success.png" class="mr-1 mt-n1"/>
                  Entregas (beta)
                </span>
              </div>
            </base-dropdown>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <div class="mt-5">
        <span
          style="width: 115px;"
          :class="filter.is_filter_pendencies && 'active'"
          class="btn btn-sm btn-outline-warning p-1 ml-2 mt-n3 text-center invert-color"
          @click="getFilterPendencies()" 
        >
          <img 
            height="25px" 
            :src="
              filter.is_filter_pendencies
                ? '/img/icons/hourglass-white.png'
                : '/img/icons/hourglass-yellow.png'
            " 
            class="invert-on-hover" />
          <span 
            :class="filter.is_filter_pendencies ? 'text-white' : 'text-warning'"
            class="invert-on-hover text-uppercase"
            style="font-size: 12px;"
          >
            Pendências
          </span>
        </span>
        <MultiFilter
          ref="multi-filter"
          @clearFilter="clearFilter()"
          :showSlotDates="false"
          :showSlotData="true"
          :defaultRange="range"
          :filterable_type="1"
          @handleFilterDate="handleFilterDate"
          @fetch="init" :filter="filter"
        >
          <template slot="status">
            <div class="col-12 mt-1">
              <BaseButtonHoverable @click="clearFilter" size="sm" type="dark" icon="infinity" platform="ios">
                TODOS
              </BaseButtonHoverable>
            </div>
            <div class="col-12 mt-1">
              <BaseButtonHoverable
                @click="filterByStatus(ContractProposalEnum.COMPLETED)"
                :active="filter.status === ContractProposalEnum.COMPLETED"
                size="sm" type="primary" icon="double-tick" platform="ios"
              >
                CONCLUÍDO
              </BaseButtonHoverable>
            </div>
            <div class="col-12 mt-1">
              <BaseButtonHoverable
                @click="filterByStatus(ContractProposalEnum.ACTIVE)"
                :active="filter.status === ContractProposalEnum.ACTIVE"
                size="sm" type="success" icon="ok--v1" platform="ios"
              >
                ATIVO
              </BaseButtonHoverable>
            </div>
            <div class="col-12 mt-1">
              <BaseButtonHoverable
                @click="filterByStatus(ContractProposalEnum.INACTIVE)"
                :active="filter.status === ContractProposalEnum.INACTIVE"
                size="sm" type="danger" icon="shutdown--v1" platform="ios"
              >
                INATIVO
              </BaseButtonHoverable>
            </div>
          </template>

          <template slot="data">
            <div class="col-md-12 mt-3 text-left">
              <label class="form-control-label">
                Vendedor
              </label>
              <PuzlSelectSearch
                :value="filter.seller_uuid"
                :loading="loadingSellers"
                v-model="filter.seller_uuid"
                customKey="seller_uuid"
                @search="handleFilterSeller"
                @input="init({})"
                clearable
                class="select-xl new-default-black-font"
                :items="$_users"
              />
            </div>
            <div class="col-md-12 mt-3 text-left">
              <label class="form-control-label">
                UF da obra {{ filter.state }}
              </label>
              <PuzlSelect
                v-model="filter.state"
                :items="$_states"
                customKey="letter"
                label="letter"
                @input="getCities(filter.state), init({})"
                class="select-xl new-default-black-font"
                :loading="loadingStates"
                :disabled="loadingStates"
              />
            </div>
            <div class="col-md-12 mt-3 text-left">
              <label class="form-control-label">
                Município da obra {{ filter.city }}
              </label>
              <PuzlSelect
                v-model="filter.city"
                :items="$_cities"
                label="title"
                customKey="title"
                class="select-xl new-default-black-font"
                @input="init({})"
                :loading="loadingCities"
                :disabled="loadingCities || !filter.state"
              />
            </div>

            <div class="col-md-12 mt-3 text-left">
              <label class="form-control-label text-uppercase">
                Cpf/Cnpj
              </label>
              <validation-provider :rules="'document'" v-slot="{errors}">
                <base-input input-classes="form-control-sm">
                  <input
                    v-model="filter.entity_document"
                    @input="validateDocument()"
                    type="text"
                    class="form-control form-control-sm"
                    v-mask="['###.###.###-##', '##.###.###/####-##']"
                  />
                </base-input>
              </validation-provider>
            </div>

            <div class="col-md-12 mt-3 text-left">
              <label class="form-control-label">
                Situação
              </label>
              <PuzlSelect
                v-model="filter.situation_id"
                :items="$_situations"
                class="select-xl new-default-black-font"
                @input="init({})"
                :loading="loadingSituations"
                :disabled="loadingSituations"
              />
            </div>
          </template>

          <template slot="bookmarks">
            <div class="col-12 mt-1">
              <BaseButtonHoverable
                @click="filterByStatus(ContractProposalEnum.PENDING_APPROVAL)"
                :active="filter.status === ContractProposalEnum.PENDING_APPROVAL"
                size="sm" type="warning" icon="hourglass" platform="ios"
              >
                PENDENTE DE APROVAÇÃO
              </BaseButtonHoverable>
            </div>
            <div class="col-12 mt-1">
              <BaseButtonHoverable
                @click="filterByStatus(ContractProposalEnum.PENDING_REVIEW)"
                :active="filter.status === ContractProposalEnum.PENDING_REVIEW"
                size="sm" type="warning" icon="inscription" platform="ios"
              >
                PENDENTE DE REVISÃO
              </BaseButtonHoverable>
            </div>
            <div class="col-12 mt-1">
              <BaseButtonHoverable
                @click="handleFilterApprovationStatus()"
                :active="filter.approvation_status"
                size="sm" type="warning" icon="payment-history" platform="ios"
              >
                APROVAÇÃO FINANCEIRA
              </BaseButtonHoverable>
            </div>
            <div class="col-12 mt-1">
              <BaseButtonHoverable
                @click="handleFilterPendingPayment()"
                :active="filter.pending_payment"
                size="sm" type="danger" icon="minus-2-math" platform="ios"
              >
                INADIMPLENTES
              </BaseButtonHoverable>
            </div>
            <div class="col-12 mt-1 mb-6">
              <BaseButtonHoverable
                @click="handleFilterHasPendingLaunch()"
                :active="filter.has_pending_launch"
                size="sm" type="danger" icon="error--v1" platform="ios"
              >
                ALERTA - SEM FINANCEIRO
              </BaseButtonHoverable>
            </div>
          </template>
        </MultiFilter>
      </div>
    </div>
    <ListContractProposal
      :source="contract_proposals"
      @fetch="init"
      :digitalSignatureConfig="digitalSignatureConfig"
      @contractProposalUpdated="updateContractAfterGuarantorEntityRegistration"
      @deletedGuarantorEntity="updateContractAfterGuarantorEntityDeletion"
      @contractProposalBusinessPartnerUpdated="updateContractAfterBusinessPartnerRegistration"
      @deletedBusinessPartner="updateContractAfterBusinessPartnerDeletion"
    />
    <ModalReport ref="modalReport"/>
    <ModalScheduleReport ref="modalScheduleReport"/>
    <ModalProposalTerms ref="modalProposalTerms"/>
    <ModalSearchCustomerConstruction ref="modalSearchCustomerConstruction"/>
    <ModalDigitalSignatureConfigurations
      ref="modalDigitalSignatureConfigurations"
      @fetch="getDigitalSignatureConfigurations"
    />
    <ModalCreateTemporaryCustomerConstruction ref="modalCreateTemporaryCustomerConstruction"/>
  </div>
</template>

<script>
import ListContractProposal from "./Shared/_List";
import {mapGetters} from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import cursorPaginate from '@/mixins/cursorPaginate'
import ModalReport from '../../Financial/Billing/Shared/_ModalReport'
import ModalScheduleReport from '@/views/Modules/Commercial/ContractProposal/Shared/_ModalScheduleReport.vue';
import ModalProposalTerms from './Shared/_ModalProposalTerms.vue'
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import ModalSearchCustomerConstruction from './Shared/_ModalSearchCustomerConstruction';
import ModalDigitalSignatureConfigurations from './Shared/_ModalDigitalSignatureConfigurations.vue';
import moment from "moment";
import ModalCreateTemporaryCustomerConstruction from './Shared/_ModalCreateTemporaryCustomerConstruction';
import {ContractProposalEnum} from "@/enum/ContractProposalEnum";
import PuzlSelectSearch from "@/components/PuzlSelectSearch.vue";
import PuzlSelect from "@/components/PuzlSelect";
import {debounce} from "@/plugins";
import {hasPermission} from "@/plugins/permissions";

export default {
  name: "IndexContractProposal",
  mixins: [cursorPaginate],
  components: {
    MultiFilter,
    BaseButtonHoverable,
    ListContractProposal,
    ModalReport,
    ModalScheduleReport,
    ModalProposalTerms,
    ModalSearchCustomerConstruction,
    ModalDigitalSignatureConfigurations,
    ModalCreateTemporaryCustomerConstruction,
    PuzlSelectSearch,
    PuzlSelect,
  },
  data() {
    return {
      filter: {
        approvation_status: 0,
        pending_payment: 0,
        doesnt_have_balance: 0,
        has_pending_launch: 0,
        status: null,
        seller_uuid: null,
        state: '',
        city: '',
        entity_document: '',
        situation_id: null,
        is_filter_pendencies: false,
      },
      type: null,
      loading: false,
      loadingSkeleton: true,
      range: {
        start: moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD 00:00:00'),
        end: moment().format('YYYY-MM-DD 00:00:00')
      },
      multi_filter: {
        null: {
          name: 'Todos',
          type: 'dark',
        },
        0: {
          name: 'Inativo',
          type: 'danger'
        },
        1: {
          name: 'Ativo',
          type: 'success',
        },
        4: {
          name: 'Proposta',
          type: 'default',
        },
        2: {
          name: 'Pendente Revisão',
          type: 'yellow',
        },
        3: {
          name: 'Pendente Aprovação',
          type: 'warning',
        },
        5: {
          name: 'Concluído',
          type: 'primary',
        },
      },
      digitalSignatureConfig: null,
      ContractProposalEnum: ContractProposalEnum,
      loadingSellers: true,
      loadingStates: true,
      loadingCities: false,
      loadingSituations: true,
      has_permission: hasPermission,
      is_new_layout: 1,
    };
  },
  computed: {
    ...mapGetters({
      contract_proposals: "contractProposal/fetch",
      $_users: "user/fetch",
      $_states: 'localizations/states',
      $_cities: 'localizations/cities',
      $_situations: "contractSituation/activeItems",
    })
  },
  mounted() {
    this.getDigitalSignatureConfigurations();
    this.init({});
    this.handleFilterSeller();
    this.getStates();
    this.getSituations();
  },
  watch: {
    // Observa mudanças no parâmetro de consulta 'type' da rota
    '$route.query.type': {
      immediate: true,
      handler(newVal) {
        this.type = newVal
        this.init({});
      }
    }
  },
  methods: {
    /**
     * Atualiza os dados do contrato após cadastrar um fiador.
     * @param {object} contractProposal
     */
    clearFilter() {
      this.filter = {
        approvation_status: 0,
        pending_payment: 0,
        doesnt_have_balance: 0,
        has_pending_launch: 0,
        seller_uuid: null,
        state: '',
        city: '',
        entity_document: '',
        situation_id: null,
        is_filter_pendencies: false,
      };

      this.init(this.filter);
    },
    /**
     * Atualiza os dados do contrato após cadastrar o fiador, para aparecer ícone no card.
     * @param {number} contractProposalId
     */
    updateContractAfterGuarantorEntityRegistration(contractProposal) {
      const foundContract = this.contract_proposals.find((item) => item.id == contractProposal.id);
      if (foundContract) {
        foundContract.guarantor_entity_id = contractProposal.guarantor_entity_id;
        foundContract.guarantorEntity = contractProposal.guarantorEntity;
      }
    },
    /**
     * Atualiza os dados do contrato após deletar o fiador.
     * @param {number} contractProposalId
     */
    updateContractAfterGuarantorEntityDeletion(contractProposalId) {
      const foundContract = this.contract_proposals.find((item) => item.id == contractProposalId);
      if (foundContract) {
        foundContract.guarantor_entity_id = null;
        delete foundContract.guarantorEntity;
      }
    },
    /**
     * Atualiza os dados do contrato após cadastrar o parceiro comercial, para aparecer ícone no card.
     * @param {number} contractProposalId
     */
    updateContractAfterBusinessPartnerRegistration(contractProposal) {
      const foundContract = this.contract_proposals.find((item) => item.id == contractProposal.id);
      if (foundContract) {
        foundContract.business_partner_id = contractProposal.business_partner_id;
        foundContract.businessPartner = contractProposal.businessPartner;
      }
    },
    /**
     * Atualiza os dados do contrato após deletar o parceiro comercial.
     * @param {number} contractProposalId
     */
    updateContractAfterBusinessPartnerDeletion(contractProposalId) {
      const foundContract = this.contract_proposals.find((item) => item.id == contractProposalId);
      if (foundContract) {
        foundContract.business_partner_id = null;
        delete foundContract.businessPartner;
      }
    },
    handleShowModalProposalTerms() {
      this.$refs.modalProposalTerms.handleCreateModal()
    },
    handleShowModalDigitalSignature() {
      this.$refs.modalDigitalSignatureConfigurations.handleCreateModal()
    },
    openModalSearchEntityAndConstruction() {
      this.$refs.modalSearchCustomerConstruction.openModal();
    },
    async handleShowModalReport() {
      await this.$refs.modalReport.openModal()
      await this.$refs.modalReport.$refs.modalSearchContract.openModal(false)
    },
    /**
     * Determina se vai usar o layout antigo ou novo do pdf. 
     * @param {boolean} is_new_layout
     */
    handleShowModalScheduleReport(is_new_layout = 0) {
      this.$refs.modalScheduleReport.openModal(is_new_layout)
    },
    handleFilterDoesntHaveBalance() {
      this.filter.doesnt_have_balance = Number(!this.filter.doesnt_have_balance)
      this.init({})
    },
    handleFilterHasPendingLaunch() {
      this.filter.has_pending_launch = !this.filter.has_pending_launch
      this.filter.has_pending_launch = Number(this.filter.has_pending_launch)
      this.init({})
    },
    handleFilterPendingPayment() {
      this.filter.pending_payment = !this.filter.pending_payment
      this.filter.pending_payment = Number(this.filter.pending_payment)
      this.init({})
    },
    handleFilterApprovationStatus() {
      this.filter.approvation_status = !this.filter.approvation_status
      this.filter.approvation_status = Number(this.filter.approvation_status)
      this.init({})
    },
    handleFilterDate(filter) {
      this.range = filter
      this.init({})
    },
    filterByStatus(filter) {
      if (this.filter.status === filter) {
        // Se o status atual for igual ao status passado, alterne entre o valor e null
        this.filter.status = null;
      } else {
        // Caso contrário, defina o status normalmente
        this.filter.status = filter;
      }
      this.init(this.filter);
    },
    init(filter = null) {
      this.startCursor(filter)
      /**
       * Será refatorado para algum plugin relacionado ao cache
       */
      for (var key in this.filter) {
        if (key === 'status') {
          continue;
        }
        if (!this.filter[key]) {
          delete this.filter[key]
        }
      }
      this.filter.type = this.type
      this.$store
        .dispatch("contractProposal/fetchItems", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then(response => {
          this.resolveCursor(response)
        })
        .catch(() => {
          this.resolveCursor()
          this.loadingSkeleton = false
        });
    },
    getDigitalSignatureConfigurations() {
      this.$store.dispatch('digitalSignatureConfiguration/show')
        .then((response) => {
          this.digitalSignatureConfig = response.data;
        });
    },
    handleShowModalCreateTemporaryCustomerConstruction() {
      this.$refs.modalCreateTemporaryCustomerConstruction.openModal();
    },
    filterByProposalStatus() {
      this.filter.status = ContractProposalEnum.PROPOSAL;
      this.init();
    },
    filterByContractStatus() {
      this.filter.status = ContractProposalEnum.ACTIVE;
      this.init();
    },
    /**
     * Inicia o loading e chama a função responsável por trazer os dados.
     * @param {string} label - Pesquisa do usuário
     */
    handleFilterSeller: function (label) {
      this.loadingSellers = true;
      this.debouncedFilterSeller(label);
    },
    /**
     * Busca os dados com base no texto digitado pelo usuário.
     * @param {string} label
     */
    debouncedFilterSeller: debounce(function (label) {
      this.$store.dispatch('user/getSearch', {
        search: label
      }).then(() => {
        this.loadingSellers = false;
      });
    }, 300),
    /**
     * Retornar todos os estados para servirem de filtro na pesquisa dos cards.
     */
    getStates() {
      this.$store.dispatch('localizations/fetchStates').then(() => this.loadingStates = false);
    },
    /**
     * @param {string} state
     */
    getCities(state) {
      this.loadingCities = true;
      this.filter.city = '';
      this.$store.dispatch('localizations/fetchCities', {uf: state})
        .finally((response) => {
          this.loadingCities = false;
        });
    },
    validateDocument: debounce(function() {
      const isDocument = this.$helper.verifyDocument(this.filter.entity_document);
      if (isDocument) {
        this.init({});
      } else if (this.filter.entity_document.length > 0) {
        this.$notify({
          type: 'warning',
          message: 'CPF/CNPJ inválido'
        });
      }
    }, 1250),
    getSituations() {
      this.$store.dispatch('contractSituation/getItems')
        .then(response => {
          this.loadingSituations = false;
        })
    },
    /**
     * Filtra 'Pendente de revisão' e 'Pendente de aprovação'.
     */
    getFilterPendencies() {
      this.filter.is_filter_pendencies = !this.filter.is_filter_pendencies;
      this.init({});
    }
  },
};
</script>

<style></style>
